.left-buttons{
    position: relative;
    width: 20%;
    height: 0;
    padding-bottom: 20%;
}
.right-buttons{
    position:fixed;
    top: 10px;
    right: 24px;
    
}